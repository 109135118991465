 <template>
  <div>
    <CCard>
      <CCardHeader class="font-weight-bold"> Edit Role </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CInput
              label="Role Name"
              v-model="role_to_edit.name"
              placeholder="Enter Role Name"
            />
          </CCol>
        </CRow>

        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-left">Permission</th>
              <th scope="col" class="text-center">Create</th>
              <th scope="col" class="text-center">Edit</th>
              <th scope="col" class="text-center">View</th>
              <th scope="col" class="text-center">Delete</th>
            </tr>
          </thead>
          <tbody class="text-center pt-5" v-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">
                <CSpinner color="primary" size="lg" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(permission_group, key) in permissions" :key="key">
              <td>{{ key | capitalizeTheFirstLetterOfEachWord }}</td>
              <template v-for="term in crud_terms">
                <td class="text-center" :key="term">
                  <template v-for="permission in permission_group">
                    <input
                      type="checkbox"
                      :value="permission.id"
                      v-model="selectedPermissions"
                      v-if="permission.name.indexOf(term) > -1"
                      :key="permission.id"
                      :checked="checkIfTrue(permission.id)"
                    />
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </table>

        <CButtonToolbar class="justify-content-between">
          <div class="justify-content-left">
            <!-- <CButton color="danger" @click="clear_all()" class="mr-2">Clear</CButton> -->
            <CButton color="info" @click="select_all()">Select All</CButton>
          </div>
          <CButton color="success" v-if="!isSaving" @click="updateRole"
            >Update Role</CButton
          >
          <CButton color="secondary" class="px-4" disabled v-else>
            <CSpinner grow size="sm" /> Updating..
          </CButton>
        </CButtonToolbar>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

const crud_terms = ["create", "update", "read", "delete"];
export default {
  name: "RoleEdit",
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editRole) {
      this.$router.push({ name: "All Roles" });
    }
    this.$store.dispatch("permissions/getPermissions", {
      Authorization: "bearer " + this.$store.state.access_token,
    });
    if (this.role) {
      this.loadData();
      this.selectPermissions();
    }
  },
  computed: {
    ...mapGetters({ editRole: "roles/editRole" }),
    ...mapState({
      permissions: (state) => state.permissions.permissions,
      isLoading: (state) => state.permissions.isLoading,
      isSaving: (state) => state.roles.isSaving,
      editRole: (state) => state.roles.editRole,
    }),
    role() {
      return this.editRole;
    },
  },
  data() {
    return {
      role_to_edit: [],
      crud_terms,
      selectedPermissions: [],
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
    };
  },
  methods: {
    checkIfTrue(permission_id) {
      return this.selectedPermissions.some((p) => p.id === permission_id);
    },
    loadData() {
      this.beforeEditingCache = JSON.stringify(this.role);
      this.role_to_edit = JSON.parse(this.beforeEditingCache)[0];
    },
    selectPermissions() {
      this.selectedPermissions = this.role_to_edit
        ? this.role_to_edit.permissions.reduce(function (
            accum,
            currentVal,
            currentIndex
          ) {
            accum[currentIndex] = currentVal.id;
            return accum;
          },
          [])
        : [];
    },
    clear_all() {
      this.role_to_edit.permissions = [];
      this.selectedPermissions = [];
    },
    select_all() {
      for (const group in this.permissions) {
        for (const permission in this.permissions[group]) {
          this.selectedPermissions.push(this.permissions[group][permission].id);
        }
      }
    },
    async updateRole() {
      if (!this.role_to_edit.name) {
        return;
      }
      await this.$store.dispatch("roles/updateRole", {
        Authorization: "bearer " + this.$store.state.access_token,
        role: this.role_to_edit,
        role_permission_id: this.selectedPermissions,
      });
      //  this.$router.push({ name: "All Roles" });
    },
  },
};
</script>